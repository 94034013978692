/*
 * Confidential and Proprietary.
 * Do not distribute without 1-800-Flowers.com, Inc. consent.
 * Copyright 1-800-Flowers.com, Inc. 2019. All rights reserved.
 */

import React, { useEffect } from 'react';
import Cookies from 'universal-cookie';
import { useSelector } from 'react-redux';

import { func, object } from 'prop-types';
import { Collapse } from '@material-ui/core';
import { getUserType } from '../../../../../../state/ducks/App/ducks/Common/Common-Selectors';
import GuestUserGreetingUI from './GuestUserGreetingUI';
import useClickStreamCustomEventsTracking from '../../../../../helpers/Personalization/useClickstreamCustomEventsTracking';
import noop from '../../../../../helpers/noop';

function GuestUserGreetingContainer({ cmsData, salesforceResponse, setShowSkeleton }) {
    const cookies = new Cookies();
    const persistedUserProfile = cookies.get('persisted_user_profile');
    const userType = useSelector(getUserType);
    const isUserEligible = userType === 'G';
    const isModuleEnabledFromCMS = cmsData?.is_module_enabled;

    const trackClickThrough = useClickStreamCustomEventsTracking({
        salesforceResponse,
        isFireImpression: true,
        page: { type: 'home' },
    });

    useEffect(() => {
        setShowSkeleton(false);
    }, []);

    const getUserName = () => {
        const { firstName } = persistedUserProfile || {};
        if (firstName?.length > 0) {
            return `${firstName[0]?.toUpperCase()}${firstName.slice(1)}`;
        }
        return '';
    };

    if (isUserEligible && isModuleEnabledFromCMS) {
        return (
            <Collapse in={isUserEligible} timeout={1500}>
                <GuestUserGreetingUI
                    userName={getUserName()}
                    blockData={cmsData}
                    trackClickThrough={trackClickThrough}
                />
            </Collapse>
        );
    }
    return null;
}

GuestUserGreetingContainer.propTypes = {
    cmsData: object.isRequired,
    salesforceResponse: object.isRequired,
    setShowSkeleton: func,
};

GuestUserGreetingContainer.defaultProps = {
    setShowSkeleton: noop,
};

export default GuestUserGreetingContainer;
